<template>
  <div class="ticket-create-view">
    <GrootHeroBanner
      :title="$t('ticket-create.heading')"
      :breadcrumbs="showBreadcrumbs ? breadcrumbItems : []" />

    <CreateTicketForm
      :validation-messages="validationMessages"
      :form-labels="formFieldLabels"
      :products="products"
      :case-types="caseTypes"
      :loading="loading"
      :exception="exception"
      :show-go-back-button="showBackButton"
      :reference-text="referenceText"
      :add-file-heading="addFileHeading"
      :submit-button-text="submitButtonText"
      :go-back-button-text="goBackButtonText"
      :add-file-text="addFileText"
      @goBack="goBack"
      @submit="submit" />
  </div>
</template>

<script>
import { applyReactInVueWithOptions } from '@/utils/react.utils'
import { GrootHeroBanner } from 'groot-components'
import { buildTicketCreateRequest } from '../../utils/request.utils'
import Constants from '../../utils/constants'
import CreateTicketForm from './CreateTicketForm.vue'
import { BREADCRUMBS_CUSTOMER_TICKETS } from '@/constants/breadcrumbs-constants'

export default {
  components: {
    GrootHeroBanner: applyReactInVueWithOptions(GrootHeroBanner),
    CreateTicketForm
  },
  props: {
    showBreadcrumbs: {
      type: Boolean,
      required: false,
      default: true
    },
    showBackButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_CUSTOMER_TICKETS.CREATE,
      formFieldLabels: {
        caseType: this.$t('ticket-create.case-type'),
        customerReference: this.$t('ticket-create.customer-reference'),
        product: this.$t('ticket-create.product'),
        subject: this.$t('ticket-create.subject'),
        content: this.$t('ticket-create.content-label'),
        orderId: this.$t('ticket-create.field-labels.order-number'),
        assetId: this.$t('ticket-create.field-labels.asset-number'),
        ttId: this.$t('ticket-create.field-labels.complaint-number'),
        receiveEmailNotification: this.$t('ticket-create.field-labels.email-notification'),
        responseEmail: this.$t('ticket-create.field-labels.response-email')
      },
      validationMessages: {
        caseType: {
          required: this.$t('validators.ticket-create.required')
        },
        customerReference: {
          maxLength: this.$t('validators.ticket-create.customer-reference')
        },
        product: {
          required: this.$t('validators.ticket-create.required')
        },
        subject: {
          required: this.$t('validators.ticket-create.required')
        },
        content: {
          required: this.$t('validators.ticket-create.required')
        },
        orderId: {
          orderId: this.$t('validators.ticket-create.order-id')
        },
        assetId: {
          assetId: this.$t('validators.ticket-create.asset-id')
        },
        ttId: {
          ttId: this.$t('validators.ticket-create.tt-id')
        },
        responseEmail: {
          required: this.$t('validators.ticket-create.required'),
          email: this.$t('validators.ticket-create.email')
        }
      },
      referenceText: this.$t('ticket-create.reference'),
      addFileText: this.$t('ticket-create.attachments.button'),
      addFileHeading: this.$t('ticket-create.attachments.heading'),
      submitButtonText: this.$t('ticket-create.buttons.create'),
      goBackButtonText: this.$t('ticket-create.buttons.go-back')
    }
  },
  computed: {
    response () {
      return this.$store.getters['CustomerTickets/TicketCreate/response']
    },
    loading () {
      return this.$store.getters['CustomerTickets/TicketCreate/loading']
    },
    exception () {
      return this.$store.getters['CustomerTickets/TicketCreate/exception']
    },
    products () {
      return Constants.CATEGORIZED_PRODUCTS
    },
    caseTypes () {
      return Constants.CASE_TYPES_OPTIONS
    }
  },
  watch: {
    response (newVal) {
      if (this.exception) {
        return
      }

      this.$emit('goToDetails', {
        name: 'tickets-details',
        params: { supportId: newVal.supportId },
        query: { created: true }
      })
    }
  },
  methods: {
    goBack () {
      this.$emit('goBack', { name: 'tickets-list' })
    },
    async submit (form) {
      await this.$store.dispatch('CustomerTickets/TicketCreate/postRequest', {
        ticketCreateRequest: buildTicketCreateRequest(form),
        files: form.files
      })
    }
  }
}
</script>
